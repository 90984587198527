import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Head from "next/head";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

import { Image } from "react-bootstrap";
import Drawer from "antd/lib/drawer";
import { isMobileOnly } from "react-device-detect";

// import Logo from "../../assets/images/belgium_immo_logo.svg";
import Logo from "../../assets/images/WinLeads-header.svg";
import NoPhoto from "../../assets/images/no-photo.png";
import AddIcon from "../../assets/images/icon-plus.svg";
import LogoutIcon from "../../assets/images/nav-bar/logout.svg";
import EstimateHomeIcon from "../../assets/images/nav-bar/estimate-home.svg";
import CompareAgencyIcon from "../../assets/images/nav-bar/compare-agency.svg";
import CheckedIcon from "../../assets/images/valid-blue.svg";
import NunitoSans from "../../assets/fonts/NunitoSans-Regular.ttf";
import NunitoSansBold from "../../assets/fonts/NunitoSans-Bold.ttf";
import CurrentStepIcon from "../../assets/images/header-step-current.svg";
import SuccessStepIcon from "../../assets/images/header-step-success.svg";
import ArrowDownIcon from "../../assets/images/arrow-down-white.svg"
import BlogsIcon from "../../assets/images/nav-bar/blogs.svg";
// import compareagencyImage from "../../assets/images/compareagency.png";
// import blogImage from "../../assets/images/blogs.png";

import { RootState } from "../../types/state";
import { agencyNavBarList } from "../../config/navBarList";
// import { config } from "../../config/siteConfigs";
import {
	// clearStepsStateAction,
	setUserProfile,
	userLogoutAction,
} from "../../actions";
import styled from "styled-components";
import { useLinkTranslation } from "../../custom-hooks/useLinkTranslation";
import { MdArrowDropDown } from "react-icons/md";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import { MaskedIcon } from "../NavBar";
import { Divider } from "antd";
import { signOut } from "next-auth/react";
import NLFLag from "../../assets/images/nlFlag.svg";
import FRFLag from "../../assets/images/frFlag.svg";
import ENGFlag from "../../assets/images/engFlag.svg";
import ArrowLang from "../../assets/images/header-lang-arrow.svg";

const LatLngs = styled.div`
	display: flex;
	/* margin-top: 50%; */
	align-items: center;
	justify-content: center;

	.active {
		background: #FC704C;
		color: var(--colorWhite);
	}

	span {
		text-transform: capitalize;
		margin: 0 6px;
		font-family: var(--fontNunitoBold);
		font-size: 14px;
		line-height: 19px;
		background: var(--bg-blue);
		border-radius: 6px;
		padding: 6px 10px;
	}
`;
const langList = [
	{
		id: "en",
		tag: "English",
		label: "english",
		flag: ENGFlag
	},
	{
		id: "fr",
		tag: "Français",
		label: "french",
		flag: FRFLag
	},
	{
		id: "nl",
		tag: "Dutch",
		label: "dutch",
		flag: NLFLag
	},
];

const HeaderContainer = ({
	title,
	step,
	metaDescription,
	canonicalHref,
	seoImage,
	isVisible = true,
	structuredData = {},
	pathParams = {},
	isHeaderShadowVisible = true,
	forceShadow = false,
	customLanguageSelectorPaths = null,
	hideItemsForAgent = true,
	redirected = false,
}: {
	title: string;
	mainPage?: boolean;
	step?: number;
	metaDescription?: string;
	canonicalHref?: string;
	isVisible?: boolean;
	structuredData?: any;
	pathParams?: any;
	seoImage?: string;
	isHeaderShadowVisible?: boolean;
	forceShadow?: boolean;
	hideItemsForAgent?: boolean;
	customLanguageSelectorPaths?: any;
	redirected?: boolean;
}) => {
	const router = useRouter();

	const dispatch = useDispatch();
	const { RouterLink, routerPush } = useLinkTranslation();

	const { locale, pathname, asPath } = router;
	const { t } = useTranslation("header");
	const account_type = useSelector(
		(state: RootState) => state?.userInfo?.account_type
	);
	const [scrollHeight, setScrollHeight] = useState<number>(0);
	const [agencyProfile, setAgencyProfile] = useState(null);
	const [logoImage, setLogoImage] = useState("");
	const navBarList = agencyNavBarList();

	const { auth, firstname, lastname, avatar } = useSelector(
		(state: RootState) => state.userInfo
	);

	const isLoggedIn = auth;

	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const [openLangList, setOpenLangList] = useState<boolean>(false);

	const navLinks = [
		{
			name: t("a.buy"),
			href: "/for-sale",
			icon: EstimateHomeIcon,
		},
		{
			name: t("a.rent"),
			href: "/for-rent",
			icon: EstimateHomeIcon,
		},
		{
			name: t("a.estimate"),
			href: "/estimate-home",
			icon: EstimateHomeIcon,
		},
		// {
		// 	name: t("a.find-agencies"),
		// 	href: "/agencies",
		// 	icon: CompareAgencyIcon,
		// },
		{
			name: t("nav-li.blogs"),
			href: "/blogs",
			icon: BlogsIcon,
		},
	];

	const goToMainPage = () => {
		routerPush(`/new-estimate`, undefined, { locale });
	};

	const Logout = async (account_type) => {
		await signOut({ redirect: false });
		dispatch(userLogoutAction(account_type));
	};

	const openSwitcherBlock = () => {
		setOpenLangList(!openLangList);
	};

	const selectLang = (lang: any) => {
		if (customLanguageSelectorPaths) {
			routerPush(customLanguageSelectorPaths[lang], undefined, {
				locale: lang,
			});
		} else {
			let _pathname = pathname;
			if (_pathname.includes("[")) {
				_pathname = _pathname.replaceAll("[city]", pathParams?.city);
				_pathname = _pathname.replaceAll("[agencyId]", pathParams?.agencyId);
				_pathname = _pathname.replaceAll("[reviewId]", pathParams?.reviewId);
				_pathname = _pathname.replaceAll(
					"[propertyId]",
					pathParams?.propertyId
				);
				_pathname = _pathname.replaceAll("[slug]", pathParams?.slug);
				_pathname = _pathname.replaceAll(
					"[listing_type]",
					pathParams?.listing_type
				);
			}

			const split = asPath.split("?");
			if (split.length > 1) {
				_pathname = _pathname + "?" + split[1];
			}
			routerPush(_pathname, undefined, {
				locale: lang,
			});
		}
	};

	// const goToLoginPage = () => {
	// 	dispatch(clearStepsStateAction());
	// 	routerPush("/login");
	// };

	const onScroll = () => {
		setScrollHeight(window.pageYOffset);
	};

	useEffect(() => {
		// i18n.changeLanguage(locale);
	}, [locale]);

	useEffect(() => {
		window.addEventListener("scroll", onScroll);

		return () => {
			window.removeEventListener("scroll", onScroll);
		};
	}, []);

	useEffect(() => {
		if (window) {
			const userProfile = window.localStorage.getItem("user_profile");
			if (userProfile) {
				dispatch(
					setUserProfile({
						...JSON.parse(userProfile),
					})
				);
			}
		}
	}, []);

	const _getAgencyProfile = async () => {
		try {
			let _agencyProfile;
			const agencyString = localStorage.getItem('agency');
			if (agencyString) {
				_agencyProfile = JSON.parse(agencyString);
			}
			setAgencyProfile({ ..._agencyProfile });
			setLogoImage(_agencyProfile?.logo_image);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		_getAgencyProfile();
	}, []);

	return (
		<>
			<Head>
				<title>{title}</title>
				<link rel="icon" href={"/favicon.ico"} />
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href={"/apple-touch-icon.png"}
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href={"/favicon-32x32.png"}
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href={"/favicon-16x16.png"}
				/>
				<link rel="manifest" href={"/site.webmanifest"} />
				<link rel="mask-icon" href={"/safari-pinned-tab.svg"} color="#3871ef" />

				<link rel="prefetch" type="font/ttf" as="font" href={NunitoSans} />
				<link rel="prefetch" type="font/ttf" as="font" href={NunitoSansBold} />
				{/* {mainPage && !isMobile && (
					<link rel="preload" as="image" href={FirstSlide} />
				)} */}
				{/* <meta name="robots" content="noindex, nofollow" /> */}
				<meta name="apple-mobile-web-app-title" content="BelgiumImmo" />
				<meta name="application-name" content="BelgiumImmo" />
				<meta name="msapplication-TileColor" content="#3871ef" />
				<meta
					name="facebook-domain-verification"
					content="30qmvq4ldi8cytef4h7ao0hwkcvp4s"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<meta name="theme-color" content="#3871ef" />
				<meta name="description" content={metaDescription} />
				{canonicalHref && <link rel="canonical" href={canonicalHref} />}
				{canonicalHref && <meta property="og:url" content={canonicalHref} />}
				<meta property="og:type" content="website" />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={metaDescription} />
				{seoImage && <meta property="og:image" content={seoImage} />}
				{seoImage && <meta property="og:image:width" content="1200" />}
				{seoImage && <meta property="og:image:height" content="630" />}
				{structuredData && (
					<script
						key="structured-data"
						type="application/ld+json"
						dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
					/>
				)}
			</Head>
			<div
				className={`Header ${isVisible ? "d-flex" : "d-none"
					} align-items-center fixed-top ${forceShadow
						? "custom-header-shadow"
						: scrollHeight > 30 && isHeaderShadowVisible
							? "shadow"
							: ""
					}`}
			>
				<div className="d-sm-block d-md-none"
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<RouterLink href="/">
						<a>
							<Image
								className={`logo`}
								width={206}
								height={48}
								src={Logo}
								alt="Logo"
							/>
						</a>
					</RouterLink>
				</div>
				<div
					style={{ marginLeft: "0px", padding: "4px 0px" }}
					className={`switcher-lang position-relative d-none d-md-block
						${openLangList ? "active-locale" : ""
						}`}
				>
					<div className="parent-lang" onClick={openSwitcherBlock}>
						<span onClick={openSwitcherBlock}>
							{locale === "nl" ? <img style={{ width: 24, height: 16 }} src={NLFLag} /> :
								locale === "fr" ? <img style={{ width: 24, height: 16 }} src={FRFLag} /> :
									<img style={{ width: 24, height: 16 }} src={ENGFlag} />}
						</span>
						<span className="d-none d-md-block" onClick={openSwitcherBlock}
							style={{
								textTransform: "none",
								marginLeft: "12px",
								fontWeight: 400,
								fontSize: 16,
								// lineHeight: 23
							}}>
							{locale === "nl" ? "Dutch" :
								locale === "fr" ? "Français" :
									"English"}
						</span>
						<span className="d-none d-md-block" style={{
							marginLeft: "12px",
						}}>
							<img src={ArrowLang} />
						</span>
					</div>
					{openLangList && (
						<div className={`lang-list ${!auth ? "p-right" : ""}`}>
							{langList.map((lang, index) => (
								<span
									className={`lang-list-child lang.id === locale ? "active" : ""`}
									key={index}
									onClick={() => selectLang(lang.id)}
								>
									{lang.flag && <img style={{ width: 24, height: 16, marginRight: 12 }} src={lang.flag} alt={`${lang.label} Flag`} />}
									{lang.tag}
									{lang.id === locale && (
										<img style={{ marginLeft: 12 }} src={CheckedIcon} alt="CheckedIcon" />
									)}
								</span>
							))}
						</div>
					)}
				</div>
				<div className="d-none d-md-block">
					<RouterLink href="/">
						<a>
							<Image
								className={`logo`}
								width={206}
								height={48}
								src={Logo}
								alt="Logo"
							/>
						</a>
					</RouterLink>
				</div>
				<div className="d-flex align-items-center">
					<div
						style={{ marginRight: "8px" }}
						className={`switcher-lang position-relative d-sm-block d-md-none
						${openLangList ? "active-locale" : ""
							}`}
					>
						<div className="parent-lang" onClick={openSwitcherBlock}>
							<span onClick={openSwitcherBlock}>
								{locale === "nl" ? <img style={{ width: 24, height: 16 }} src={NLFLag} /> :
									locale === "fr" ? <img style={{ width: 24, height: 16 }} src={FRFLag} /> :
										<img style={{ width: 24, height: 16 }} src={ENGFlag} />}
							</span>
							<span className="d-none d-md-block" onClick={openSwitcherBlock}
								style={{
									textTransform: "none",
									marginLeft: "12px",
									fontWeight: 400,
									fontSize: 16,
									// lineHeight: 23
								}}>
								{locale === "nl" ? "Dutch" :
									locale === "fr" ? "Français" :
										"English"}
							</span>
							<span className="d-none d-md-block" style={{
								marginLeft: "12px",
							}}>
								<img src={ArrowLang} />
							</span>
						</div>
						{openLangList && (
							<div className={`lang-list ${!auth ? "p-right" : ""}`}>
								{langList.map((lang, index) => (
									<span
										className={`lang.id === locale ? "active" : ""`}
										key={index}
										onClick={() => selectLang(lang.id)}
									>
										{lang.flag && <img style={{ width: 24, height: 16, marginRight: 12 }} src={lang.flag} alt={`${lang.label} Flag`} />}
										{lang.tag}
										{lang.id === locale && (
											<img style={{ marginLeft: 12 }} src={CheckedIcon} alt="CheckedIcon" />
										)}
									</span>
								))}
							</div>
						)}
					</div>
					{auth ? (
						<>
							{/* <div className="d-none d-md-block"> */}
							<div className="d-block">
								<Dropdown
									arrow
									overlay={
										<div
											style={{
												backgroundColor: "white",
												boxShadow: "0px 4px 10px rgba(103, 139, 216, 0.3)",
												borderRadius: "10px",
												padding: 20,
											}}
										>
											{navBarList.map((el, index) => {
												return (
													<RouterLink
														href={el.href}
														key={index}
														locale={locale}
													>
														<a>
															<div className="d-flex flex-row align-items-center mb-3">
																<MaskedIcon
																	selected={router.pathname === el.href}
																	icon={el.img}
																/>

																<div
																	style={{
																		color:
																			router.pathname === el.href
																				? "#FC704C"
																				: "#1D2E5B",
																		fontSize: 16,
																	}}
																>
																	{t(`nav-li.${el.id}`)}
																</div>
															</div>
														</a>
													</RouterLink>
												);
											})}
											<div
												style={{ cursor: "pointer" }}
												onClick={() => Logout(account_type)}
												className="d-flex flex-row align-items-center"
											>
												<MaskedIcon selected={false} icon={LogoutIcon} />

												<div
													style={{
														color: "#1D2E5B",
														fontSize: 16,
													}}
												>
													{t(`nav-li.logout`)}
												</div>
											</div>
										</div>
									}
									trigger={["click"]}
								>
									<div
										style={{ cursor: "pointer" }}
										className="d-flex flex-row align-items-center"
									>
										<Image
											style={{ width: 30, height: 30 }}
											alt="avatar"
											className="mr-2"
											src={logoImage ? logoImage : NoPhoto}
											roundedCircle
										/>
										<span
											style={{ color: "#ffff" }}
											className="d-none d-md-block mr-2"
										>
											{agencyProfile?.company_name}
										</span>
										{/* <span className="d-none d-md-block mr-2"
											style={{
												background: "#FC704C",
												color: "#ffff",
												borderRadius: "4px",
												padding: "2px 5px",
												fontWeight: "700",
												fontSize: "10px",
												lineHeight: "14px"
											}}>
											PRO
										</span>
										<Image
											style={{ width: 10, height: 10 }}
											alt="icon"
											// className="mr-2"
											src={ArrowDownIcon}
										/> */}
									</div>
								</Dropdown>
							</div>
							{/* <RouterLink href="/agency-settings">
								<Image
									style={{ width: 30, height: 30 }}
									alt="avatar"
									className="mr-2 d-block d-md-none"
									src={logoImage ? logoImage : NoPhoto}
									roundedCircle
								/>
							</RouterLink> */}
						</>
					) : (
						<RouterLink href={`/login${redirected ? "?redirected=true" : ""}`}>
							<a rel="nofollow" className="parent-sign-in-btn">
								<span className="sign-in-btn">
									{t("button.login")}
									{/* <img src={LoginArrow} alt="LoginArrow" /> */}
								</span>
							</a>
						</RouterLink>
					)}
				</div>
			</div>
		</>
	);
};

export default HeaderContainer;
