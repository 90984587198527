import { NavBarLists } from "../types/nav-bar-lists";
import dashboardIcon from "../assets/images/nav-bar/dashboard.svg";
import sellerLeadsIcon from "../assets/images/seller-leads/seller-leads-icon.png";
import statisticsIcon from "../assets/images/statistics.svg";
import settingsIcon from "../assets/images/seller-leads/settings-icon.png";
import propertiesIcon from "../assets/images/nav-bar/properties.svg";
import savedPropertyIcon from "../assets/images/saved-properties-icon.png";
import saveSearchesNew from "../assets/images/saved-searches-new.png";
import accountSettingsNew from "../assets/images/account-settings-new.png";
// import settingsIcon from "../assets/images/nav-bar/settings.svg";

const myProperties: NavBarLists = {
	id: "myProperties",
	href: "/properties",
	img: propertiesIcon,
};

const myEstimations: NavBarLists = {
	id: "myEstimates",
	href: "/properties",
	img: propertiesIcon,
};

const settings: NavBarLists = {
	id: "settings",
	href: "/settings",
	img: accountSettingsNew,
};

const agencySettings: NavBarLists = {
	id: "settings",
	href: "/agency-settings",
	img: settingsIcon,
};

const sellerLeads: NavBarLists = {
	id: "sellerLeads",
	href: "/appointments",
	img: sellerLeadsIcon,
};

const dataBox: NavBarLists = {
	id: "statistics",
	href: "/statistics",
	img: statisticsIcon,
};


const agencyNavBarList = () => [dataBox, sellerLeads, agencySettings];

export { agencyNavBarList };
